.center {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
}

.gridSizeInput {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.row {
  display: flex;
  flex-direction: row;
}

.cell {
  padding: 16px;
  border: 1px solid black;
  /* width: 35px;
  height: 35px; */
}

.alive {
  background-color: yellow;
}

.dead {
  background-color: grey;
  color: white;
}